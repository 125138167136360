.table tr td {
  width: fit-content;
}

.table-body tr {
  cursor: pointer;
}

.accessory-add-container{
  min-height: 100vh;
  background-color: white;
}

#AccessoryInformationGrid {
  padding-left: 14px;
}

.heading {
  margin: 0;
  text-align: center;
}
div#TransferUnAllocatedPaymentGrid_dialogEdit_wrapper{
  min-width: 90vw !important;
  min-height: 90vh  !important;
  max-width: 90vw !important;
  max-height: 90vh !important;
  // z-index: 758957;
}
div:has(#TransferUnAllocatedPaymentGrid_dialogEdit_wrapper){
  z-index: 9834448 !important;
  // min-height: 60vh !important;
  // max-width: 60vw !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding-right: 0px !important;
}


.e-dlg-container{
  padding-right: 0px !important;
  
}
#ProductFinishSizeGrid_dialogEdit_wrapper_dialog-content{
  scroll-behavior: none;
}
// #TransferUnAllocatedPaymentGrid_dialogEdit_wrapper > .e-footer-content button.e-control.e-btn.e-lib.e-primary.e-flat{

//   visibility:hidden;
// }

div#ProductFinishSizeGrid_dialogEdit_wrapper_title {
  display: none;
}


input#ConfirmConsigneeName,
#CommissionAmountPerUnit,
input#AbsoluteCommissionAmount,
#BookAmount ,
#PrimaryCommissionPercentOnAdjustment,
#SecondaryCommissionPercentOnAdjustment,
#AdjustmentAmount,
#BasePriceCompany,
#BasePriceAgent,
input#TransferUnAllocatedPaymentGridReceiptAmount
{
  text-align: right;
  padding-right: 4px;
}

#ApproveCommissionAmountPerUnit
  #ApproveCommissionAmountPerUnit {
    display: flex;
    justify-content: center;
    align-items: end;
    margin-top: 16px;

  // padding-bottom: -6px;
}
div#ApproveCommissionAmountPerUnit,
#ApproveAbsoluteCommissionAmount, 
  #ConfirmConsigneeName,
  #ApproveSecondaryCommissionPercentOnAdjustment,
  #ApprovePrimaryCommissionPercentOnAdjustment
  {
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 16px;
}

// checkbox



.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 30%;
  left: 40%;
  height: 15px;
  width: 15px;
  // padding: 5px;
  background-color: #fff;
  border: 1px solid #09325b;
}
label.checkbox-container {
  width: 100%;
}

.checkbox:checked ~ .checkmark {
  background-color:#09325b;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox:checked ~ .checkmark:after {
  display: block;
}

.checkbox:checked ~ .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

// ///////////////////////////////////////////////////
/* EmailComposeComponent.css */
.email-editor{
  padding: 20px;
}
.email-compose-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  
}

.form-section {
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
}

.email-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editor-section {
  margin-bottom: 20px;
}

.send-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.send-button:hover {
  background-color: #0056b3;
}

/* Style for CC and BCC sections */
.cc-bcc-section {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cc-bcc-button {
  margin-right: 10px;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.cc-bcc-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: none; /* Hide initially */
}

.show-input .cc-bcc-input {
  display: block; /* Show when class is applied */
}
.attech-file-content input {
    width: 100%;
    height: 100%;
    z-index: 35 !important;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
}
//email section
.email-footer {
  display: flex;
  justify-content: space-between;
  padding: 2px 50px;
  align-items: flex-start;
}

.attech-file-content {
  width: 100px;
  height: 40px;
  position: relative;
  margin: auto;
}
.attech-file-content button {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
   top: 0px; 
   left: 0px;
  cursor: pointer;
  background: #09325b;
  color: white;
  border: none;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  letter-spacing: 1px;
}
.send-email{
  cursor: pointer;
  background: #09325b;
  color: white;
  border: none;
  font-weight: 600;
  font-family: 'Source Sans Pro';
  letter-spacing: 1px;
  padding: 10px;
}
ul.atteched-file {
  list-style: none;
  padding: 0px;
}
ul.atteched-file  li {
  padding: 10px;
  background: #09325b;
  color: white;
  font-weight: 600;
  margin-top: 10px;
  position: relative;
}

ul.atteched-file  li .plus {
  transform: rotateZ(46deg);
  font-weight: 500;
  color: red;
    /* padding: 5px; */
    font-size: 30px;
}
ul.atteched-file  li span img {

    width: 30px;
    margin-top: 5px;
    margin-left: 5px;
    color: red !important;

}
ul.atteched-file  li span {
  position: absolute;
  top: 0px;
  left: 101%;

 
  cursor: pointer;
}
.not-supported-file {
  position: absolute;
  top: 14px;
  left: 50px;
  /* background: red; */
  color: red;
  z-index: 4555;
}
span.jodit-toolbar-button.jodit-toolbar-button_size_middle.jodit-toolbar-button_variant_initial.jodit-toolbar-button_file.jodit-ui-group__file {
  display: none;
}

.Cc-span{
  cursor: pointer;
}
.Cc-span:hover{
  color: #000;
}
.Bcc-span{
  cursor: pointer;
}
.Bcc-span:hover{
  color: #000;
}
.cc-bcc-spans{
  color: #09325b;
}
.from-email {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* EmailMultiSelect.css */
.custom-multi-select .item-checkbox {
  display: none; /* Hide checkboxes */
}

/* EmailMultiSelect.css */
.custom-multi-select .multi-select__option:focus {
  background-color: #09325b !important;
  color: white !important;
}

.custom-multi-select .multi-select__input:checked + .multi-select__label {
  background-color: gray !important;
  color: white !important;
}

/* EmailMultiSelect.css */
.custom-multi-select .multi-select__option--focus {
  background-color: #09325b !important;
  color: white !important;
}

.custom-multi-select .multi-select__option--selected {
  background-color: gray !important;
  color: white !important;
}

.css-wsp0cs-MultiValueGeneric {
  color: #09325b !important;
}
.css-t3ipsp-control:hover {
  border-color: #09325b !important;
}
.css-t3ipsp-control:focus {
  border-color: #09325b !important;
}
.css-t3ipsp-control:active{
  border-color: #09325b !important;
}
.css-13cymwt-control {
  border-color: #09325b !important;
}
.custom-select .Select__menu {
  left: 0;
}

.custom-select .Select__option {
  text-align: left !important;
  color: red !important;
}
.css-1nmdiq5-menu
 {
  color: #09325b !important;
  text-align: left;
}
.css-b62m3t-container:hover {

  border-color: #09325b !important;
}
.css-b62m3t-container{
  border-color: #09325b !important;
}
.css-1nmdiq5-menu:hover ~ .css-13cymwt-control{
  border-color: #09325b !important;
}

span.e-input-group.e-control-wrapper.e-valid-input {
  border-radius: 5px;
  min-height: 38px;
  border-color: darkseagreen;
}

input#textbox_18 {
  margin-top: auto;
  margin-bottom: auto;
}

input#textbox_17 {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}
span.e-input-group.e-control-wrapper.e-disabled.e-valid-input {
  min-height: 38px;
}
.jodit-wysiwyg{
  min-height: 200px !important;
}
a.jodit-status-bar-link {
  display: none;
}

.jodit-wysiwyg > p {
  text-align: left ;
}

h2.account-amount {
  /* margin-top: 7px; */
  /* padding: 0px; */
  padding-top: 30px;
  padding-bottom: 2px;
  color: #09325b;
}
// .drop-downss-div .e-input-group:not(.e-success):not(.e-warning):not(.e-error), .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) 
  
// {
//   width: 200px !important;
// }
.drop-downss-div div div{
  width: 250px !important;
}
.drop-downss-div div {
  // flex:  25%;
width: 250px;
}
input#Supplier ,
input#CustomerName,
input#Currency,
input#SalesAgent,
input#SalesStatus
{
  min-width: 250px;
}
.drop-downss-div span.e-input-group.e-control-wrapper {
  width: 200px;
}
.drop-downss-div  input#FromDate,
.drop-downss-div input#ToDate{
  min-width: 200px !important ;
}
.drop-downss-div {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  align-items: end;
  gap: 10px;
  padding-left: 20px;
  padding-bottom: 5px;
  transition: 0.3s;
}

button#get-data-btn {
  border: none;
  outline: none;
  background: #00335b;
  color: white;
  padding: 4px 10px;
}

button#get-data-btn:hover {
  transition: 0.3s;
  background: #fff;
  color: #00335b;
  // padding: 4px 10px;
}

.Container-ApproveCommissionAmountPerUnit {
  /* WIDTH: 100%; */
  // BACKGROUND: RED;
  /* Z-INDEX: 55555599 !IMPORTANT; */
  /* HEIGHT: 100%; */
  POSITION: RELATIVE;
}

.ApproveCommissionAmountPerUnitWrap,
.ApproveAbsoluteCommissionAmountWrap {
  WIDTH: 100%;
  HEIGHT: 115%;
  POSITION: ABSOLUTE;
  /* TOP: 0; */
}

 .required::after { 
  content: 'required  *'; 
  margin-right: 4px; 
  top:0px;
  right:0% ;
  position: absolute;
  color: red; 
  margin: 0px;

  font-size: large;
} 
.required{
  position: relative;
}



#SalesAgent
{
  // color: red !important;
  -webkit-text-fill-color: #00335b ;
}
